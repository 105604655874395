.example+.highlight {
	border-top: none;
	margin-top: 0;
	border-radius: 0 0 3px 3px;
}

.highlight {
	margin: 1rem 0 2rem;
	border: 1px solid #e8ebf3;
	border-radius: 3px;
	font-size: .9375rem;
	max-height: 40rem;
	overflow: auto;
	background: $background;
	background: rgba(255, 215, 0, 0.115);
	pre {
		margin-bottom: 0;
		background-color: rgba(5, 117, 230, 0.1);
	}
	.hll {
		background-color: #ffc;
	}
	.c {
		color: #999;
	}
	.k {
		color: #069;
	}
	.o {
		color: #555;
	}
	.cm {
		color: #999;
	}
	.cp {
		color: #099;
	}
	.c1,
	.cs {
		color: #999;
	}
	.gd {
		background-color: #fcc;
		border: 1px solid #c00;
	}
	.ge {
		font-style: italic;
	}
	.gr {
		color: red;
	}
	.gh {
		color: #030;
	}
	.gi {
		background-color: #cfc;
		border: 1px solid #0c0;
	}
	.go {
		color: #aaa;
	}
	.gp {
		color: #009;
	}
	.gu {
		color: #030;
	}
	.gt {
		color: #9c6;
	}
	.kc,
	.kd,
	.kn,
	.kp,
	.kr {
		color: #069;
	}
	.kt {
		color: #078;
	}
	.m {
		color: #f60;
	}
	.na {
		color: $yellow;
	}
	.nb {
		color: #366;
	}
	.nc {
		color: #0a8;
	}
	.no {
		color: #360;
	}
	.nd {
		color: #99f;
	}
	.ni {
		color: #999;
	}
	.ne {
		color: #c00;
	}
	.nf {
		color: #c0f;
	}
	.nl {
		color: #99f;
	}
	.nn {
		color: #0cf;
	}
	.nt {
		color: $danger;
	}
	.nv {
		color: #033;
	}
	.ow {
		color: $black;
	}
	.w {
		color: #bbb;
	}
	.mf,
	.mh,
	.mi,
	.mo {
		color: #f60;
	}
	.sb,
	.sc {
		color: #c30;
	}
	.sd {
		font-style: italic;
		color: #c30;
	}
	.s2,
	.se,
	.sh {
		color: #c30;
	}
	.si {
		color: #a00;
	}
	.sx {
		color: #c30;
	}
	.sr {
		color: #3aa;
	}
	.s1 {
		color: #c30;
	}
	.ss {
		color: #fc3;
	}
	.bp {
		color: #366;
	}
	.vc,
	.vg,
	.vi {
		color: #033;
	}
	.il {
		color: #f60;
	}
	.css {
		.nt+.nt {
			color: #999;
		}
		.o {
			color: #999;
			+.nt {
				color: #999;
			}
		}
	}
	.language-bash::before,
	.language-sh::before {
		color: #009;
		content: "$ ";
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.language-powershell::before {
		color: #009;
		content: "PM> ";
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}