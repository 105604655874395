.chart-visitors {
	min-height: 18rem;
}

.chart-tasks {
	height: 15rem;
}

.chart-donut,
.chart-pie {
	height: 21rem;
}

.chartsh {
	height: 16rem;
}

.chartwidget {
	height: 17rem;
}

.chartmorris {
	height: 19rem;
}

.dropshadow {
	-webkit-filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
	filter: drop-shadow(0 -6px 4px rgb(227, 232, 239));
}

.chart,
.map {
	position: relative;
	padding-top: 56.25%;
}

.chart-square,
.map-square {
	padding-top: 100%;
}

.chart-content,
.map-content {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.map-header {
	margin-bottom: 1.5rem;
	height: 15rem;
	position: relative;
	margin-bottom: -1.5rem;
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 10rem;
		background: linear-gradient(to bottom, rgba(245, 247, 251, 0) 5%, $background 95%);
		pointer-events: none;
	}
}

.map-header-layer {
	height: 100%;
}

.map-static {
	height: 120px;
	width: 100%;
	max-width: 640px;
	background-position: center center;
	background-size: 640px 120px;
}

@-webkit-keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}

@keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: .32;
	}
}

.chart-circle {
	display: block;
	height: 8rem;
	width: 8rem;
	position: relative;
	margin: 0 auto;
	canvas {
		margin: 0 auto;
		display: block;
		max-width: 100%;
		max-height: 100%;
	}
}

.chart-circle-xs {
	height: 2.5rem;
	width: 2.5rem;
	font-size: .8rem;
}

.chart-circle-xs1 {
	height: 110px;
	width: 110px;
	font-size: .8rem;
}

.chart-circle-sm {
	height: 4rem;
	width: 4rem;
	font-size: .8rem;
}

.chart-circle-lg {
	height: 10rem;
	width: 10rem;
	font-size: .8rem;
}

.chart-circle-value {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	line-height: 1;
	small {
		display: block;
		color: #a7b4c9;
		font-size: .9375rem;
	}
}

.sparkgraph {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	height: 43px;
	canvas {
		width: 100% !important;
	}
}

.amcharts-chart-div a,
.canvasjs-chart-container a {
	display: none !important;
}

.rotation {
	-webkit-animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

.amChartsLegend.amcharts-legend-div {
	display: none;
}

.graph canvas {
	width: 100% !important;
}

.map-header1 {
	margin-bottom: 1.5rem;
	height: 23.2rem;
	position: relative;
	margin-bottom: -1.5rem;
	border: 1px solid #e8ebf3;
}

.axgmap {
	position: relative;
	width: 100%;
	height: 500px;
}

.axgmap-img {
	width: 30px;
	height: 30px;
	text-align: center;
	background: $white;
}

.gm-style .gm-style-iw {
	padding: 20px;
	overflow: hidden;
	display: block;
	margin: 0 auto;
	padding-bottom: 0;
	text-align: center;
	width: 250px !important;
	h4 {
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
	}
}

.gm-ui-hover-effect {
	opacity: .6;
	top: 0 !important;
	right: 0 !important;
}

.gm-style .gm-style-iw a.btn {
	margin-bottom: 6px;
}

@media (min-width: 992px) and (max-width: 1359px) {
	.map-content-width {
		width: 45%;
	}
	.map-width {
		width: 55%;
	}
}

@media (min-width: 1360px) {
	.map-content-width {
		width: 45%;
	}
	.map-width {
		width: 55%;
	}
}

.map-content-width {
	.mCSB_inside>.mCSB_container {
		margin-right: 0;
	}
	.mCSB_scrollTools {
		.mCSB_dragger .mCSB_dragger_bar {
			float: right;
			background: $black-3 !important;
		}
		.mCSB_draggerRail {
			display: none !important;
		}
		.mCSB_dragger .mCSB_dragger_bar:hover {
			background: $black-5 !important;
		}
	}
}

.mh-500 {
	max-height: 500px;
}

@media (max-width: 992px) and (min-width: 767px) {
	.map-width {
		display: contents;
	}
}

.relative {
	position: relative !important;
}

.zindex1 {
	z-index: 1 !important;
}

.zindex2 {
	z-index: 2 !important;
}

.map-absolute {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 30px;
	border-bottom: 0 !important;
}