.range {
	display: table;
	position: relative;
	height: 25px;
	margin-top: 20px;
	background-color: #f5f5f5;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 2px $black-1;
	box-shadow: inset 0 1px 2px $black-1;
	cursor: pointer;
	input[type=range] {
		-webkit-appearance: none !important;
		-moz-appearance: none !important;
		-ms-appearance: none !important;
		-o-appearance: none !important;
		appearance: none !important;
		display: table-cell;
		width: 100%;
		background-color: transparent;
		height: 25px;
		cursor: pointer;
		outline: 0;
		&::-webkit-slider-thumb,
		&::-moz-slider-thumb {
			-webkit-appearance: none !important;
			-moz-appearance: none !important;
			-ms-appearance: none !important;
			-o-appearance: none !important;
			appearance: none !important;
			width: 11px;
			height: 25px;
			color: $white;
			text-align: center;
			white-space: nowrap;
			vertical-align: baseline;
			border-radius: 0;
			background-color: #999;
		}
	}
	output {
		display: table-cell;
		padding: 3px 5px 2px;
		min-width: 40px;
		color: $white;
		background-color: #999;
		text-align: center;
		text-decoration: none;
		border-radius: 4px;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		width: 1%;
		white-space: nowrap;
		vertical-align: middle;
		-webkit-transition: all .5s ease;
		-moz-transition: all .5s ease;
		-o-transition: all .5s ease;
		-ms-transition: all .5s ease;
		transition: all .5s ease;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: -moz-none;
		-o-user-select: none;
		user-select: none;
	}
	&.range-success {
		input[type=range] {
			&::-webkit-slider-thumb,
			&::-moz-slider-thumb {
				background-color: $success;
			}
		}
		output {
			background-color: $success;
		}
		input[type=range] {
			outline-color: $success;
		}
	}
	&.range-info {
		input[type=range] {
			&::-webkit-slider-thumb,
			&::-moz-slider-thumb {
				background-color: #00d6e6;
			}
		}
		output {
			background-color: #00d6e6;
		}
		input[type=range] {
			outline-color: #00d6e6;
		}
	}
	&.range-warning {
		input[type=range] {
			&::-webkit-slider-thumb,
			&::-moz-slider-thumb {
				background-color: $yellow;
			}
		}
		output {
			background-color: $yellow;
		}
		input[type=range] {
			outline-color: $yellow;
		}
	}
	&.range-danger {
		input[type=range] {
			&::-webkit-slider-thumb,
			&::-moz-slider-thumb {
				background-color: $danger;
			}
		}
		output {
			background-color: $danger;
		}
		input[type=range] {
			outline-color: $danger;
		}
	}
}